// Fontawesome Pro
// Set Font path from fontawesome.css
$fa-font-path : 'https://storage.googleapis.com/anoncom-assets/assets/fonts' !default;

@import '~@fortawesome/fontawesome-pro/scss/fontawesome';
@import '~@fortawesome/fontawesome-pro/scss/brands';
@import '~@fortawesome/fontawesome-pro/scss/regular';
@import '~@fortawesome/fontawesome-pro/scss/solid';
@import '~@fortawesome/fontawesome-pro/scss/light';
@import '~@fortawesome/fontawesome-pro/scss/duotone';


.fatheme-key {
    --fa-primary-color: rgb(169 158 77);
    --fa-secondary-color: rgb(171 163 71);
}
